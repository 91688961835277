import Link from 'components/elements/Link';
import { useIntl } from 'react-intl';
import { Action } from 'redux';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Form, Formik, FormikErrors, FormikProps } from 'formik';
import classNames from 'classnames';
import { User } from '@neo1/client/lib/entities/user/types';
import { isValidString } from '@neo1/core/utils/strings';
import { AppCoreState } from 'redux/types';
import {
  getActingUserSupervisees,
  login,
} from 'modules/Authentification/redux/thunks';
import { Events, useAmplitude } from 'contexts/instrumentation';
import { loginEventData } from 'contexts/instrumentation/amplitude';
import FormField from 'components/elements/form/Field';
import Footer from 'components/elements/form/Footer';
import PasswordInput from 'components/elements/form/fields/PasswordInput';
import TextInput from 'components/elements/form/fields/TextInput';
import Button, { ButtonSize } from 'components/elements/Button';
import InfoBox from 'components/elements/InfoBox';
import { isEmail } from '@neo1/client';
import messages from './messages';
import styles from './DefaultLogin.module.css';

type Credentials = {
  login: string;
  password: string;
};

type Props = {
  onLoginSuccess: (user: User) => void;
  onLoginError: (error: any, userEmail?: string) => void;
};

const INITIAL_VALUES: Credentials = {
  login: '',
  password: '',
};

const DefaultLogin = ({ onLoginSuccess, onLoginError }: Props) => {
  const intl = useIntl();
  const dispatch: ThunkDispatch<AppCoreState, void, Action> = useDispatch();
  const { logEvent } = useAmplitude();

  const validate = (values: Credentials) => {
    const errors: FormikErrors<Credentials> = {};

    if (!isValidString(values.login)) {
      errors.login = intl.formatMessage(messages.requiredFieldError);
    }

    if (!isValidString(values.password)) {
      errors.password = intl.formatMessage(messages.requiredFieldError);
    }

    return errors;
  };

  const onSubmit = async (
    credentials: Credentials,
    { setStatus }: FormikProps<Credentials>,
  ) => {
    try {
      const user = await dispatch(login(credentials));
      const supervisees = await dispatch(getActingUserSupervisees());

      onLoginSuccess(user);

      logEvent({
        event: Events.UserLogin,
        data: loginEventData(user, supervisees.length >= 0),
      });

      setStatus(null);
      return user;
    } catch (err) {
      onLoginError(
        err,
        isEmail(credentials.login) ? credentials.login : undefined,
      );
      setStatus(err.message);
      return null;
    }
  };

  const intlForgotMyPassword = intl.formatMessage(messages.forgotMyPassword);
  const intlRegisterNewCompany = intl.formatMessage(
    messages.registerNewCompany,
  );

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validate={validate}
    >
      {({ isSubmitting, status: error }: FormikProps<Credentials>) => (
        <Form className={styles.form}>
          {!isSubmitting && error && (
            <InfoBox type="error" className={styles.error}>
              {error}
            </InfoBox>
          )}
          <FormField
            className={styles.field}
            name="login"
            title={intl.formatMessage(messages.userName)}
            required
          >
            <TextInput
              className={styles.input}
              autoCapitalize="none"
              autoCorrect="off"
              withClearBtn={false}
            />
          </FormField>
          <FormField
            className={classNames([styles.field, styles.noBottomMargin])}
            name="password"
            title={intl.formatMessage(messages.password)}
            required
          >
            <PasswordInput className={styles.input} />
          </FormField>
          <div className={styles.linkContainer}>
            <Link
              aria-label={intlForgotMyPassword}
              bold
              standAlone
              title={intlForgotMyPassword}
              to="/forgot_password"
            >
              {intlForgotMyPassword}
            </Link>
          </div>
          <Footer className={styles.footer}>
            <Button
              label="Log in"
              type="submit"
              size={ButtonSize.Large}
              loading={isSubmitting}
              name="loginButton"
              className={styles.btnLogin}
            />
            <div className={styles.linkContainer}>
              New to Neo1?&nbsp;
              <Link
                bold
                aria-label={intlRegisterNewCompany}
                standAlone
                title={intlRegisterNewCompany}
                to="/signup"
              >
                {intlRegisterNewCompany}
              </Link>
            </div>
          </Footer>
        </Form>
      )}
    </Formik>
  );
};

export default DefaultLogin;
