import classNames from 'classnames';
import { ButtonTheme } from 'components/elements/Button';
import { ReactNode } from 'react';
import CtaButton from '../CtaButton';
import DropdownBtn from '../DropdownBtn';
import HeaderDivider from '../HeaderDivider';
import TextFilter from '../TextFilter';
import { Cta, Dropdown, Filter } from '../types';
import styles from './Subheader.module.css';

type Props = {
  mainCta: Cta;
  secondCta: Cta;
  thirdCta: Cta;
  dropdown: Dropdown;
  mainFilter: Filter;
  pageActions?: ReactNode;
  className?: string;
};

const Subheader = ({
  mainCta,
  secondCta,
  thirdCta,
  dropdown,
  mainFilter,
  pageActions,
  className,
}: Props) => {
  if (!pageActions && !mainFilter && !secondCta && !mainCta && !dropdown) {
    return null;
  }

  const hasLeft = Boolean(pageActions);

  const hasRight = Boolean(mainFilter || secondCta || mainCta || dropdown);

  return (
    <div
      className={classNames(styles.container, className)}
      data-testid="pageSubheader"
    >
      {hasLeft && (
        <div className={styles.left}>
          <HeaderDivider className={styles.divider} />
          {pageActions}
        </div>
      )}

      {hasRight && (
        <div className={classNames(styles.right, !hasLeft && styles.alone)}>
          {mainFilter && (
            <>
              <HeaderDivider className={styles.divider} />
              <TextFilter filter={mainFilter} />
            </>
          )}
          {dropdown && (
            <>
              <HeaderDivider className={styles.divider} />
              <DropdownBtn dropdown={dropdown} />
            </>
          )}
          {thirdCta && (
            <>
              <HeaderDivider className={styles.divider} />
              <CtaButton
                cta={thirdCta}
                theme={
                  thirdCta.popover ? ButtonTheme.Ghost : ButtonTheme.Outlined
                }
                withIcon
              />
            </>
          )}
          {secondCta && (
            <>
              <HeaderDivider className={styles.divider} />
              <CtaButton
                cta={secondCta}
                theme={
                  secondCta.popover ? ButtonTheme.Ghost : ButtonTheme.Outlined
                }
                withIcon
              />
            </>
          )}
          {mainCta && (
            <>
              <HeaderDivider className={styles.divider} />
              <CtaButton cta={mainCta} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

Subheader.defaultProps = {
  pageActions: undefined,
  className: undefined,
};

export default Subheader;
