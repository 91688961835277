import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import Button from 'components/elements/Button';
import { useAmplitude, Events } from 'contexts/instrumentation';
import { selectActingCompany } from 'modules/Authentification/redux/selectors';
import { isCompanyUSBased } from '@neo1/client/lib/entities/company/utils';
import stepMessages from './messages';
import messages from '../../messages';
import styles from '../../ActivateCompany.module.css';

const GetStarted = () => {
  const { logEvent } = useAmplitude();
  const company = useSelector(selectActingCompany);

  useEffect(() => {
    logEvent({ event: Events.ViewedCompanyActivationRequest });
  }, []);

  const intl = useIntl();

  return (
    <>
      <header className={styles.formHeader}>
        <h3>{intl.formatMessage(stepMessages.title)}</h3>
      </header>
      <main className={styles.formMain}>
        <ul className={styles.featureList}>
          <li>{intl.formatMessage(stepMessages.companyName)}</li>
          <li>{intl.formatMessage(stepMessages.companyType)}</li>
          <li>{intl.formatMessage(stepMessages.incorporationDate)}</li>
          <li>{intl.formatMessage(stepMessages.industry)}</li>
          <li>{intl.formatMessage(stepMessages.companySize)}</li>
          <li>{intl.formatMessage(stepMessages.vat)}</li>
          {isCompanyUSBased(company) && (
            <li>{intl.formatMessage(stepMessages.dunsNumber)}</li>
          )}
          <li>{intl.formatMessage(stepMessages.shareDetails)}</li>
        </ul>
      </main>
    </>
  );
};
export const GetStartedActions = ({ className }: { className: string }) => {
  const intl = useIntl();
  const { isSubmitting } = useFormikContext();
  return (
    <footer className={className}>
      <Button
        label={intl.formatMessage(messages.footer.btnStart)}
        type="submit"
        id="btnContinue"
        loading={isSubmitting}
      />
    </footer>
  );
};

export default GetStarted;
