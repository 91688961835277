import { generatePath } from 'react-router';
import { CompanyFeature } from '@neo1/client/lib/entities/company/constants';
import wrapAsync from 'utils/wrapAsync';
import {
  AppMenuRoute,
  hasAmazonPurchase,
  hasSpendManagement,
} from 'config/routes/utils';
import { UserLevel } from '@neo1/client/lib/entities/user/constants';
import Index from './pages';
import TabTitle from './components/Title';
import {
  ROUTE_NAME_AUTHORIZE,
  ROUTE_PATH_AUTHORIZE,
  ROUTE_PATH_AUTHORIZE_SPEND,
  ROUTE_PATH_AUTHORIZE_BUDGET,
  ROUTE_PATH_AUTHORIZE_PURCHASE,
  ROUTE_NAME_AUTHORIZE_SPEND,
  ROUTE_NAME_AUTHORIZE_BUDGET,
  ROUTE_NAME_AUTHORIZE_PURCHASE,
} from './constants';
import tabsMessages from './messages';

const { tabTitles } = tabsMessages;

const authorizeRoute: AppMenuRoute = {
  path: ROUTE_PATH_AUTHORIZE,
  name: ROUTE_NAME_AUTHORIZE,
  menu: {
    iconClass: 'checklist',
    userLevels: [UserLevel.COMPANY],
    companyFeatures: [CompanyFeature.Spend, CompanyFeature.AmazonPurchase],
    hasBadge: true,
  },
  children: [
    {
      title: () => (
        <TabTitle
          name={ROUTE_NAME_AUTHORIZE_BUDGET}
          title={tabTitles[ROUTE_NAME_AUTHORIZE_BUDGET]}
        />
      ),
      strict: true,
      show: hasSpendManagement,
      path: ROUTE_PATH_AUTHORIZE_BUDGET,
      defaultPath: generatePath(ROUTE_PATH_AUTHORIZE_BUDGET),
      name: ROUTE_NAME_AUTHORIZE_BUDGET,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "authorizeBudgets" */
            './pages/Budgets'
          ),
      ),
    },
    {
      title: () => (
        <TabTitle
          name={ROUTE_NAME_AUTHORIZE_PURCHASE}
          title={tabTitles[ROUTE_NAME_AUTHORIZE_PURCHASE]}
        />
      ),
      strict: true,
      show: hasAmazonPurchase,
      path: ROUTE_PATH_AUTHORIZE_PURCHASE,
      name: ROUTE_NAME_AUTHORIZE_PURCHASE,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "authorizeSupplies" */
            './pages/Supplies'
          ),
      ),
    },
    {
      title: () => (
        <TabTitle
          name={ROUTE_NAME_AUTHORIZE_SPEND}
          title={tabTitles[ROUTE_NAME_AUTHORIZE_SPEND]}
        />
      ),
      defaultPath: generatePath(`${ROUTE_PATH_AUTHORIZE_SPEND}?v=approve`),
      show: hasSpendManagement,
      path: ROUTE_PATH_AUTHORIZE_SPEND,
      name: ROUTE_NAME_AUTHORIZE_SPEND,
      exact: true,
      component: wrapAsync(
        () =>
          import(
            /* webpackChunkName: "authorize" */
            './pages/Spend'
          ),
      ),
    },
    {
      name: ROUTE_NAME_AUTHORIZE,
      path: ROUTE_PATH_AUTHORIZE,
      component: Index,
      hideTab: true,
    },
  ],
};

export default authorizeRoute;
