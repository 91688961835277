import { CompanyFeature } from '@neo1/client/lib/entities/company/constants';
import { UserLevel } from '@neo1/client/lib/entities/user/constants';
import { AppMenuRoute } from 'config/routes/utils';
import { generatePath } from 'react-router';
import wrapAsync from 'utils/wrapAsync';
import {
  ROUTE_PATH_PURCHASE_BUDGETS,
  ROUTE_NAME_PURCHASE_BUDGETS,
} from './constants';

const route: AppMenuRoute = {
  path: ROUTE_PATH_PURCHASE_BUDGETS,
  name: ROUTE_NAME_PURCHASE_BUDGETS,
  title: 'Budget',
  defaultPath: generatePath(ROUTE_PATH_PURCHASE_BUDGETS),
  exact: true,
  component: wrapAsync(
    () =>
      import(
        /* webpackChunkName: "budgets" */
        '.'
      ),
  ),
  menu: {
    iconClass: 'budget',
    userLevels: [UserLevel.COMPANY],
    companyFeatures: [CompanyFeature.Spend],
    hasBadge: true,
    path: generatePath(ROUTE_PATH_PURCHASE_BUDGETS),
  },
};

export default route;
