export const ACTIVE_PATH = '/activate/:token/:userId';

export const ACTIVE_USER_PATH = '/activate_user/:token/:userId';

export const SIGN_UP_PATH = '/signup';

export const COOKIE_POLICY_PATH = '/cookie_policy';

export const FORGOT_PASSWORD_PATH = '/forgot_password';

export const LOGGED_OUT_PATH = '/logged_out';

export const RESET_PASSWORD_PATH = '/reset_password/:token/:userId';
export const PENDING_ACTIVATION_PATH = '/pending_activation/:userEmail';

export const RESET_PASSWORD_AFTER_ACTIVATION_PATH =
  '/reset_password/:token/:userId/activation';
