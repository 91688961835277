import TagManager from 'utils/gtm';
import { AppConfig } from 'config/types';

export enum Event {
  SignupStarted = 'signupStarted',
  Stage0Finished = 'stage0Finished',
  Stage1Finished = 'stage1Finished',
  Stage2Finished = 'stage2Finished',
}

export const initialize = (gtmId: AppConfig['gtmId']) => {
  if (!gtmId) return;
  TagManager.initialize({ gtmId, nonce: window.currentCspNonce });
};

export const sendEvent = (event: Event) => {
  // !important keep this for debugging
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.info(
      `%c Send GTM event: ${event} `,
      'background: black; color: yellow',
    );
  }
  if (!TagManager?.dataLayer) return;
  TagManager.dataLayer({ dataLayer: { event } });
};
