import wrapAsync from 'utils/wrapAsync';
import Page from 'components/layout/Page';
import withDocumentTitle from 'components/composed/DocumentTitle';
import AfterLogout from './Logout';
import {
  ACTIVE_PATH,
  ACTIVE_USER_PATH,
  SIGN_UP_PATH,
  COOKIE_POLICY_PATH,
  FORGOT_PASSWORD_PATH,
  LOGGED_OUT_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_AFTER_ACTIVATION_PATH,
  PENDING_ACTIVATION_PATH,
} from './constants';

const loader = <Page isLoading />;

const routes = [
  {
    path: ACTIVE_PATH,
    name: 'activate',
    exact: true,
    component: wrapAsync(
      () =>
        import(
          /* webpackChunkName: "activate" */
          './ActivateUser'
        ),
      loader,
    ),
  },
  {
    path: ACTIVE_USER_PATH,
    name: 'activateUser',
    exact: true,
    component: wrapAsync(
      () =>
        import(
          /* webpackChunkName: "activateUser" */
          './ActivateUser'
        ),
      loader,
    ),
  },
  {
    path: SIGN_UP_PATH,
    name: 'signup',
    exact: true,
    component: withDocumentTitle(
      'Signup',
      wrapAsync(
        () =>
          import(
            /* webpackChunkName: "signup" */
            './Signup'
          ),
        loader,
      ),
    ),
  },
  {
    path: COOKIE_POLICY_PATH,
    name: 'cookiePolicy',
    exact: true,
    component: wrapAsync(
      () =>
        import(
          /* webpackChunkName: "cookiePolicy" */
          'components/layout/App/CookiePolicy'
        ),
      loader,
    ),
  },
  {
    path: FORGOT_PASSWORD_PATH,
    name: 'forgotPassword',
    exact: true,
    component: withDocumentTitle(
      'Reset/change password',
      wrapAsync(
        () =>
          import(
            /* webpackChunkName: "forgotPassword" */
            './ForgotPassword'
          ),
        loader,
      ),
    ),
  },
  {
    path: LOGGED_OUT_PATH,
    name: 'afterLogout',
    exact: true,
    component: AfterLogout,
  },
  {
    path: RESET_PASSWORD_PATH,
    name: 'confirmResetPassword',
    exact: true,
    component: withDocumentTitle(
      'Reset password',
      wrapAsync(
        () =>
          import(
            /* webpackChunkName: "confirmResetPassword" */
            './ResetPassword'
          ),
        loader,
      ),
    ),
  },
  {
    path: RESET_PASSWORD_AFTER_ACTIVATION_PATH,
    name: 'confirmResetPasswordAfterActivation',
    exact: true,
    component: withDocumentTitle(
      'Reset password',
      wrapAsync(
        () =>
          import(
            /* webpackChunkName: "confirmResetPasswordAfterActivation" */
            './ResetPassword'
          ),
        loader,
      ),
    ),
  },
  {
    path: PENDING_ACTIVATION_PATH,
    name: 'userInPendingActivation',
    exact: true,
    component: withDocumentTitle(
      'Request activation link',
      wrapAsync(
        () =>
          import(
            /* webpackChunkName: "userInPendingActivation" */
            './UserInPendingActivation'
          ),
        loader,
      ),
    ),
  },
];

export default routes;
